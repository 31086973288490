import { currentOrigin } from '@glass/env/modules/origins/constants';

import { QA_PREFIX, SEO_TITLE_PREFIX_ROCKET } from '@glass/shared/modules/images/constants';
import { createOptionsSlug, reFileExtension } from '@glass/shared/modules/images/imageOptionsSlug';

const CLOUDFLARE_IMAGE_PREFIX = '/cdn-cgi/image';
const CLOUDFLARE_SETTING_DELIMITER = ',';

export const WIDTH_KEY = 'w';
export const HEIGHT_KEY = 'h';
export const FIT_KEY = 'fit';
export const QUALITY_KEY = 'q';

const defaultCloudflareOptions = {
  f: 'auto',
};

const generateImageSrc = ({
  seoTitle,
  seoTitlePrefix = SEO_TITLE_PREFIX_ROCKET,
  path,
  width,
  height,
  cloudflareCdn = true,
  external,
  fit,
  quality = 80,
  origin = process.env.NEXT_PUBLIC_IMAGE_ORIGIN || currentOrigin,
}) => {
  if (!path) {
    return '';
  }

  let finalPath = path[0] === '/' ? path : `/${path}`;

  if (origin?.includes?.('://qa.') && !external) {
    finalPath = `/${QA_PREFIX}${finalPath}`;
  }
  // rocket resume cloudflare worker
  if (seoTitle && !external) {
    const newEnding = finalPath.replace(reFileExtension, `/${seoTitle}.$1`);
    finalPath = `${seoTitlePrefix}${newEnding}`;
  }

  // cloudflare image cdn
  if (cloudflareCdn) {
    let cfFit;

    if (height) {
      cfFit = 'cover';
    } else if (width) {
      cfFit = 'scale-down';
    }

    const responsiveImageSlug = createOptionsSlug(
      {
        ...defaultCloudflareOptions,
        [FIT_KEY]: fit || cfFit,
        [HEIGHT_KEY]: height,
        [WIDTH_KEY]: width,
        [QUALITY_KEY]: quality,
      },
      { settingsDelimiter: CLOUDFLARE_SETTING_DELIMITER },
    );

    finalPath = `${CLOUDFLARE_IMAGE_PREFIX}/${responsiveImageSlug}${finalPath}`;
  }

  return `${origin}${finalPath}`;
};

export default generateImageSrc;
